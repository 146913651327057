const CURSOR_STYLES = {
  default: {
    cursor: "none",
    width: "16px",
    height: "16px",
    borderRadius: "50%",
    backgroundColor: "white",
    position: "fixed",
    border: "1px solid rgba(255, 255, 255, 0.18)",
    backdropFilter: "blur(10px)",
    pointerEvents: "none",
    zIndex: "10000",
    backgroundColor: "rgba(10, 36, 99, 0.5)",
  },
  hover: {
    transform: "scale(2)",
    backgroundColor: "rgba(10, 36, 99, 0.5)",
  },
};

const createCursor = () => {
  const cursor = document.createElement("div");
  Object.assign(cursor.style, CURSOR_STYLES.default);
  return cursor;
};

const handleMouseMove = (cursor) => (e) => {
  cursor.style.left = `${e.pageX}px`;
  cursor.style.top = `${e.pageY}px`;
};

const updateCursorStyle = (cursor) => (isHovering) => {
  Object.assign(
    cursor.style,
    isHovering ? CURSOR_STYLES.hover : CURSOR_STYLES.default
  );
};

const addEventListeners = (cursor) => {
  document.addEventListener("mousemove", handleMouseMove(cursor));
  document.querySelectorAll(".hover-effect").forEach((el) => {
    el.addEventListener("mouseenter", () => updateCursorStyle(cursor)(true));
    el.addEventListener("mouseleave", () => updateCursorStyle(cursor)(false));
  });
};

const appendToBody = (cursor) => {
  document.body.appendChild(cursor);
};

const initCustomCursor = () => {
  const cursor = createCursor();
  addEventListeners(cursor);
  appendToBody(cursor);
};

document.addEventListener("DOMContentLoaded", initCustomCursor);
